<template>
  <div>
    <loading
      :active="isLoading"
      :is-full-page="fullPage"
      :z-index="1100"
    ></loading>
    <div class="row mt-5">
      <div class="col-4">
        <div>
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-start"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add-tes-fed"
            v-if="!readOnly"
          >
            <i class="bi bi-plus fs-6 text-gray-700"></i>aggiungi tesserato
          </button>
          <AddTesseratoFederale @refreshTesFedList="refreshTesFedList" />
        </div>
      </div>
      <div class="col-4 text-center">
        <div v-if="!readOnly">
          <button
            type="button"
            class="btn btn-light text-gray-700 fs-6 text-center"
            data-bs-toggle="modal"
            data-bs-target="#modal_esporta_federali"
          >
            <i class="bi bi-download fs-6 text-gray-700 me-1"></i>esporta lista
            tesserati filtrata
          </button>
        </div>
      </div>
      <ModalEsportaFederali />
      <div class="col-4">
        <div class="text-end mt-4" style="margin-right: 19px">
          Visualizzati
          <b v-if="record > 0">
            {{ rowsToSkip + fetchRows - (fetchRows - 1) }}
            -
            {{
              rowsToSkip + fetchRows > record ? record : rowsToSkip + fetchRows
            }}
          </b>
          <b v-else> 0 </b>
          elementi di <b>{{ record }}</b>
        </div>
      </div>
    </div>
  </div>
  <br />

  <div class="mx-auto my-auto text-center" v-if="!loaded">
    <div class="spinner-border text-gray-600" role="status"></div>
    <span class="text-gray-600 ps-3 fs-2">Loading</span>
  </div>
  <div
    v-else-if="tesserati_federali.length === 0 && loaded"
    class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
  >
    <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
    <span class="text-gray-700 ps-3 fs-2"> Attenzione! Nessun risultato. </span>
    <span
      type="button"
      data-bs-toggle=""
      data-bs-target=""
      aria-expanded="false"
      aria-controls=""
      class="text-gray-600 pt-4"
      @click.prevent="$emit('resetFilters')"
    >
      <i class="bi bi-arrow-clockwise fs-2 text-gray-800"> </i
    ></span>
  </div>
  <div
    v-else-if="status != 200 && status != 204"
    class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
  >
    <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
    <span class="text-gray-700 ps-3 fs-2">
      Attenzione! Si è verificato un errore. Riprovare più tardi.
    </span>
    <span
      type="button"
      data-bs-toggle=""
      data-bs-target=""
      aria-expanded="false"
      aria-controls=""
      class="text-gray-600 pt-4"
    >
      <router-link v-if="isEnabled('fnLinkBachecaCompleta')" to="/bacheca">
        <i class="bi bi-house fs-2 text-gray-800"> </i
      ></router-link>
      <router-link v-else to="/bacheca-public">
        <i class="bi bi-house fs-2 text-gray-800"> </i></router-link
    ></span>
  </div>

  <div v-else>
    <Datatable
      :table-header="tableHeader"
      :table-data="tesserati_federali"
      :total="record"
      :current-page="currentPage"
      :rows-per-page="fetchRows"
      :sortLabel="sortColumn"
      :order="sortOrder"
      @sort="setSortOrderColumn"
      @items-per-page-change="setFetchRows"
      @current-change="setCurrentPage"
    >
      <template v-slot:cell-stagione="{ row: data }">{{
        data.stagione
      }}</template>
      <template v-slot:cell-tesserato="{ row: data }"
        ><div class="tab-long">
          {{ data.nominativo_completo }}
        </div></template
      >
      <template v-slot:cell-codice_snm="{ row: data }">
        <span v-if="data.codice_snm > 0">{{ data.codice_snm }}</span
        ><span v-else>-</span></template
      >
      <template v-slot:cell-categoria_qualifica="{ row: data }">{{
        data.tipo_tessera
      }}</template>
      <template v-slot:cell-data_emissione="{ row: data }"
        ><div class="text-center">{{ data.data_emissione }}</div></template
      >
      <template v-slot:cell-data_scadenza="{ row: data }"
        ><div class="text-center">{{ data.data_scadenza }}</div></template
      >
      <template v-slot:cell-comitato_regionale="{ row: data }">{{
        data.comitato_regionale
      }}</template>
      <template v-slot:cell-options="{ row: data }">
        <div class="btn-group">
          <button
            class="btn btn-sm dropdown p-1"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_view-tes-fed"
            @click="setTesserato(data.id)"
          >
            <i class="bi bi-eye text-dark fs-5"></i>
          </button>
          <button
            class="btn btn-sm dropdown p-1"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i class="bi bi-three-dots-vertical text-dark fs-4"></i>
          </button>
          <ul class="dropdown-menu">
            <a
              href="#"
              v-if="isEnabled('fnTesseratiFederaliEditTesseramento')"
              data-bs-toggle="modal"
              data-bs-target="#kt_modal_edit-tes-fed"
              @click="setIdTesserato(data.id)"
            >
              <li role="presentation" class="navi-item">
                <div class="b-dropdown-text text-start py-2 px-4">
                  <div class="navi-link">
                    <i class="bi bi-pen text-dark fs-5"></i>
                    <span role="button" class="text-dark ps-1 fs-7">
                      Modifica tesseramento
                    </span>
                  </div>
                </div>
              </li></a
            >
            <a
              href="#"
              v-if="isEnabled('fnTesseratiFederaliDeleteTesseramento')"
              @click="delTesseramento(data.id, data.id_persona)"
            >
              <li role="presentation" class="navi-item">
                <div class="b-dropdown-text text-start py-2 px-4">
                  <div class="navi-link">
                    <i class="bi bi-trash text-dark fs-5"></i>
                    <span class="text-dark ps-1 fs-7">
                      Elimina Tesseramento
                    </span>
                  </div>
                </div>
              </li></a
            >
            <router-link
              :to="`/persone/dettaglio-persona/storico-persona/${data.id_persona}`"
            >
              <li role="presentation" class="navi-item">
                <div class="b-dropdown-text text-start py-2 px-4">
                  <div class="navi-link">
                    <i class="bi bi-eye text-dark fs-5"></i>
                    <span class="text-dark ps-1 fs-7">
                      Visualizza storico
                    </span>
                  </div>
                </div>
              </li>
            </router-link>
            <a
              href="#"
              v-if="data.data_emissione"
              @click="stampaTessera(data.id, data.id_persona, data.stagione)"
            >
              <li role="presentation" class="navi-item">
                <div class="b-dropdown-text text-start py-2 px-4">
                  <div class="navi-link">
                    <i class="bi bi-printer text-dark fs-5"></i>
                    <span class="text-dark ps-1 fs-7"> Stampa Tessera </span>
                  </div>
                </div>
              </li></a
            >
          </ul>
        </div>
      </template>
    </Datatable>
    <ViewTesseratoFederale
      :tesserato="tesserato"
      :isLoadingDetail="isLoadingDetail"
    />
    <EditTesseratoFederale
      :idTesserato="idTesserato"
      :tesserato="tesserato"
      :isLoadingDetail="isLoadingDetail"
      @refreshTesFedList="refreshTesFedList"
    />
  </div>
</template>

<script>
import Datatable from "../../../kt-datatable/KTDatatable.vue";
import { ref, computed, onMounted } from "vue";
import AddTesseratoFederale from "./AddTesseratoFederale.vue";
import ViewTesseratoFederale from "./ViewTesseratoFederale.vue";
import EditTesseratoFederale from "./EditTesseratoFederale.vue";
import { useStore } from "vuex";
import { useFormatDateYMD, format } from "@/composables/formatDate";
import { stampaTesseraSingola } from "@/requests/tesseratoUnaSocRequests";

import {
  viewTesseratiFederali,
  deleteTesseramento,
} from "@/requests/tesseratiFederaliRequest";

import ModalEsportaFederali from "./ModalEsportaFederali.vue";

import { alertSuccess, alertFailed } from "@/composables/swAlert";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

import isEnabled from "@/composables/isEnabled.js";

export default {
  name: "TableTesseratiFederali",
  components: {
    Datatable,
    AddTesseratoFederale,
    ViewTesseratoFederale,
    EditTesseratoFederale,
    ModalEsportaFederali,
    Loading,
  },
  emits: ["getTesseratiFederaliList", "resetFilters"],
  props: {},
  setup(props, { emit }) {
    const store = useStore();
    const tableHeader = ref([
      {
        name: "Stagione",
        key: "stagione",
      },
      {
        name: "Tesserato",
        key: "tesserato",
      },
      {
        name: "Codice SNM",
        key: "codice_snm",
      },
      {
        name: "Tipo qualifica",
        key: "categoria_qualifica",
      },
      {
        name: "Data emissione",
        key: "data_emissione",
        align: "center",
      },
      {
        name: "Data scadenza",
        key: "data_scadenza",
        align: "center",
      },
      {
        name: "Comitato",
        key: "comitato_regionale",
      },
      {
        key: "options",
        sortable: false,
      },
    ]);

    const setFetchRows = (e) => {
      store.commit("setFetchRowsTesseratoFederale", e);
      emit("getTesseratiFederaliList");
    };
    const setCurrentPage = (page) => {
      store.commit("setCurrentPageTesseratoFederale", page);
      emit("getTesseratiFederaliList");
    };
    const setSortOrderColumn = ({ columnName, order }) => {
      store.commit("setSortColumnTesseratoFederale", columnName);
      store.commit("setSortOrderTesseratoFederale", order);
      emit("getTesseratiFederaliList");
    };

    //richiesta Api
    const isLoadingDetail = ref(false);

    const tesserato = ref({});
    const setTesserato = async (id) => {
      isLoadingDetail.value = true;
      tesserato.value = {};
      await viewTesseratiFederali(id).then((res) => {
        isLoadingDetail.value = false;
        if (res) {
          tesserato.value = res;
        } else {
          alertFailed(
            "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
      });
    };

    const idTesserato = ref(null);
    const setIdTesserato = async (id) => {
      idTesserato.value = id;
    };

    const refreshTesFedList = () => {
      emit("getTesseratiFederaliList");
    };

    const isLoading = ref(false);

    const delTesseramento = (id_Tesseramento, id_Persona) => {
      Swal.fire({
        title: "Sei Sicuro?",
        text: "Il tesseramento verrà eliminato definitivamente.",
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: "Annulla",
        confirmButtonText: "Elimina",
        allowOutsideClick: false,
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-warning",
        },
      }).then(async (e) => {
        if (e.isDismissed) {
          return;
        }
        if (e.isConfirmed) {
          isLoading.value = true;
          await deleteTesseramento(id_Tesseramento, id_Persona).then((res) => {
            if (res.status == 200) {
              alertSuccess("Tesseramento eliminato correttamente");
              emit("getTesseratiFederaliList");
            } else {
              alertFailed(
                res.data.message
                  ? res.data.message
                  : "Attenzione! Si è verificato un errore. Riprovare più tardi"
              );
            }
            isLoading.value = false;
          });
        }
      });
    };

    const stampaTessera = async (id_tesseramento, id_persona, stagione) => {
      isLoading.value = true;
      await stampaTesseraSingola(
        id_tesseramento,
        0,
        id_persona,
        stagione,
        0,
        0
      ).then((res) => {
        isLoading.value = false;
        if (res.status == 200) {
          console.log(res);
        } else {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
        }
      });
    };

    return {
      tesserati_federali: computed(() =>
        store.getters.getStateFromName("resultstesserati_federali_list")
      ),
      loaded: computed(() =>
        store.getters.getStateFromName("loadedtesserati_federali_list")
      ),
      record: computed(() =>
        store.getters.getStateFromName("recordtesserati_federali_list")
      ),
      status: computed(() =>
        store.getters.getStateFromName("statustesserati_federali_list")
      ),
      // readOnly: computed(() =>
      //   store.getters.getStateFromName("read_onlytesserati_federali_list")
      // ),
      readOnly: computed(() => store.getters.readOnlySelectedRoute),

      currentPage: computed(() => store.getters.currentPageTesseratoFederale),
      rowsToSkip: computed(() => store.getters.rowsToSkipTesseratoFederale),
      fetchRows: computed(() => store.getters.fetchRowsTesseratoFederale),
      sortColumn: computed(() => store.getters.sortColumnTesseratoFederale),
      sortOrder: computed(() => store.getters.sortOrderTesseratoFederale),
      setFetchRows,
      setCurrentPage,
      setSortOrderColumn,
      tableHeader,
      tesserato,
      idTesserato,
      setTesserato,
      setIdTesserato,
      refreshTesFedList,
      useFormatDateYMD,
      format,
      isLoadingDetail,
      isLoading,
      delTesseramento,
      stampaTessera,
      isEnabled,
    };
  },
};
</script>

<style scoped></style>
